<template>
  <div></div>
</template>

<script>
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'depositSuccess',
  metaInfo: {
    title: `${locale.getMessage('meta.deposit_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.deposit_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.deposit_keyword')}` }]
  },
  components: {},
  created() {
    this.checkOnlineDepositSuccessfulReturnUrl()
  },
  methods: {
    checkOnlineDepositSuccessfulReturnUrl() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: {
          action: 'applyBonus'
        }
      })
    }
  }
}
</script>
